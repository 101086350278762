@font-face {
  font-family: 'Roboto';
  font-weight: bold;
  font-style: normal;
  src: url(../assets/fonts/Roboto-Bold.ttf) format('opentype');
}

@font-face {
  font-family: 'Roboto';
  font-weight: normal;
  font-style: normal;
  src: url(../assets/fonts/Roboto-Medium.ttf) format('opentype');
}

@font-face {
  font-family: 'Roboto';
  font-weight: lighter;
  font-style: normal;
  src: url(../assets/fonts/Roboto-Light.ttf) format('opentype');
}
