@import '../../styles/settings.scss';

.results {
  border-bottom: 1px solid $gray-normal;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  position: relative;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 0 1em;
}

.results__content {
  font-size: 1.2em;
}

.results__title {
  font-family: $headerPageFont;
}
.results__closeIcon {
  position: absolute;
  top: 0.5em;
  left: 1em;
  cursor: pointer;
}
