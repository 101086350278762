@import '../../styles/settings.scss';

.addProduct__container {
  display: flex;
  flex-direction: column;
}

.addProduct {
  display: flex;
  justify-content: center;
  align-self: center;
  flex-direction: column;
  padding: 0.5em 1em;
}
.addproduct_header {
  font-family: $headerPageFont;
  text-align: center;
  padding: 0.5em 0;
}

.addproduct_group {
  border: none;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  border-bottom: $borderprimary;
}
.addproduct__text {
  width: 100%;
  margin: 0.3em 0;
  padding-left: 1em;
}
.addproduct__select {
  border: none;
  border-top: $borderprimary;
  width: 100%;
  padding: 1em;
  font-family: $orderPaginationFont;
  font-size: 1.3em;
  font-weight: bold;
}

.imgform__image {
  width: 100%;
}

.file-selector-toimage {
  display: none;
}

.errors {
  padding: 0.5em 1em 1em;
  // color: $danger;
  font-family: $searchResultsHeader;
  font-size: 0.9em;
}
.errors__title {
  padding-bottom: 0.5em;
}
.errors__list {
  list-style: none;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-items: center;
}

.errors__item {
  text-transform: capitalize;
  text-align: center;
  padding-bottom: 0.5em;
}

.addproduct__buttons {
  display: flex;
  justify-content: center;
  column-gap: 1em;
}

@media (min-width: 769px) {
  .addProduct {
    max-width: 768px;
  }
}
