@import '../../../styles/settings.scss';
.productCard {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  min-height: 250px;
}
.productCard__imgcontainer {
  position: relative;
}
.ProductCard__image {
  width: 40vw;
  height: 40vw;
  object-fit: fill;
}
.productCard__icon {
  background-color: aliceblue;
  font-size: 1.2em;
  padding: 0.3em;
  border-radius: 50%;
  position: absolute;
  top: 0.2em;
  right: 0.8em;
}
.productCard__summary {
  display: flex;
  flex-direction: column;
  min-height: 75px;
  justify-content: space-between;
  align-items: center;
}
.productCard__name {
  font-family: $productCardFont;
  font-size: 0.8em;
  line-height: 24px;
}
.productCard__price {
  font-family: $searchResultsHeader;
  font-weight: lighter;
  font-size: 1em;
}

@media only screen and (min-width: 481px) and (max-width: 768px) {
  .productCard__name,
  .productCard__price {
    font-size: 1.2em;
  }
  .productCard__icon {
    font-size: 1.5em;
    padding: 0.3em;
  }
}
@media only screen and (min-width: 769px) and (max-width: 1024px) {
  .productCard__name,
  .productCard__price {
    font-size: 1.2em;
  }
  .productCard__icon {
    font-size: 1.5em;
    padding: 0.3em;
  }
}

@media only screen and (min-width: 1025px) {
  .ProductCard__image {
    width: 30vw;
    height: 30vw;
    object-fit: fill;
  }
  .productCard__name,
  .productCard__price {
    font-size: 1.7em;
  }
  .productCard__icon {
    font-size: 1.8em;
    padding: 0.3em;
  }
}
