@import '../../../styles/settings.scss';

.productlist__list {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(2, minmax(100px, 1fr));
  grid-auto-rows: auto;
  list-style: none;
}

@media only screen and (min-width: 1024px) {
  .productlist__list {
    grid-template-columns: repeat(3, minmax(100px, 1fr));
  }
}
