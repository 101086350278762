@import '../../styles/settings.scss';
.title {
  display: none;
}

.App-header {
  border-bottom: 1px solid $gray-normal;
  width: 100%;
  height: 20%;
}
.App-header__nav {
  display: flex;
  width: 100%;
  padding: 0.5em 0.5em;
}

.App-header__container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 500px;
}

.App-header__list {
  display: flex;
  list-style: none;
  width: 100%;
  justify-content: flex-end;
}
.App-header__item {
  display: flex;
  margin-left: 0.5em;
}
.App-header__link {
  text-decoration: none;
  color: $black-dark;
  display: flex;
  align-items: center;
}
.App-header__text {
  display: none;
  font-size: 1.2em;
  // text-transform: uppercase;
  font-weight: 800;
}
.App-header__icon {
  font-size: 1.2em;
  margin-left: 0.2em;
}

.App-header__logo {
  width: 50px;
  height: 50px;
}

@media only screen and (min-width: 481px) {
  .App-header__list {
    justify-content: space-evenly;
  }

  .App-header__link {
    text-decoration: none;
    color: $black-dark;
    display: flex;
    align-items: center;
  }
  .App-header__text {
    display: inline-block;
  }
  .App-header__icon {
    font-size: 1.5em;
    margin-left: 0.1em;
  }
  .App-header__logo {
    width: 100px;
    height: 100px;
  }

  .App-header__container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 1300px;
  }
}
