// Colors
$white-background: #f2f6f9;
$white-oficial-background: #fafafa;
$colorborder: #ebebeb;
$black-dark: #33393b;
$gray-normal: #c7c6c6;
$gray-light: #f9f9f9;
$gray-cardproduct: #294a5a;

// fonts

$orderPaginationFont: 'Belgika';
$searchResultsHeader: 'Farro';
$productCardFont: 'Khmer';
$orderselectFont: 'Khbaph';
$headerPageFont: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
  Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

// Border
$borderprimary: 1px solid $gray-normal;

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

// Global classes (Design System)
.App {
  color: $black-dark;
  font-family: $productCardFont;
}

.inputtext {
  outline: none;
  border: none;
  background-color: $gray-light;
  font-family: $headerPageFont;
  font-size: 0.7em;
  padding: 0.5em;
  margin: 0;
  box-sizing: border-box;
}

.formLabel {
  font-family: $headerPageFont;
  font-size: 1em;
  display: flex;
  justify-content: center;
  align-items: baseline;
  column-gap: 1em;
}

.checkboxForm {
  appearance: none;
  background-color: #fff;
  margin: 0;
  font: inherit;
  color: currentColor;
  width: 1.2em;
  height: 1.2em;
  border: 0.15em solid currentColor;
  border-radius: 0.15em;
  transform: translateY(-0.075em);
  display: flex;
  justify-content: center;
  align-items: center;
}

.checkboxForm::before {
  content: '';
  width: 0.65em;
  height: 0.65em;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em $gray-cardproduct;
}

.checkboxForm:checked::before {
  transform: scale(1);
}

.btn {
  outline: none;
  border: $borderprimary;
  color: $black-dark;
  background: none;
  font-family: $headerPageFont;
  padding: 0.5em;
  margin: 0.5em 0;
}

.btn:hover {
  background-color: $gray-normal;
}

.btn:active {
  background-color: $gray-cardproduct;
  color: $gray-light;
}
// breackpoints

// mobile.

// Tablet
@media only screen and (min-width: 481px) and (max-width: 768px) {
}

// Laptop
@media only screen and (min-width: 769px) and (max-width: 1024px) {
}

// Desktop
@media only screen and (min-width: 1025px) {
}
